import type { App, Plugin } from 'vue';
import { useAzureAppInsights } from '@/shared/azure-application-insights';
const appInsights = useAzureAppInsights();

const createVueUncaughtErrorHandler = (
  enableAzureLogging: boolean
): Plugin => ({
  install: (app: App) => {
    app.config.errorHandler = (error, instance, info) => {
      const source = 'Vue Uncaught Error Handler';
      if (enableAzureLogging) {
        appInsights.logError(error as any, source, {
          Instace: instance,
          Info: info
        });
      } else {
        console.error(`[${source}]`, error, info);
      }
    };
  }
});

export default createVueUncaughtErrorHandler;
