import type { App, Plugin } from 'vue';
import { useAzureAppInsights } from '@/shared/azure-application-insights';
const appInsights = useAzureAppInsights();

const createRuntimeWarningHandler = (enableAzureLogging: boolean): Plugin => ({
  install: (app: App) => {
    app.config.warnHandler = (msg, instance, trace) => {
      if (enableAzureLogging) {
        appInsights.logEvent('Vue runtime warning.', {
          msg: msg,
          instace: instance,
          trace: trace
        });
      }
    };
  }
});

export default createRuntimeWarningHandler;
